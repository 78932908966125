import React, {useEffect, useRef, useState} from 'react';
import {
  Border1,
  Border2,
  Border3,
  Border4,
  Border5, Border6,
  FrameBody, FrameNow,
  FrameTpe,
  FrameVideo,
  ImgBg1,
  ImgBg2,
  TxtA,
  TxtDes1,
  TxtNow,
  TxtPro,
  TxtTitle,
  Wrapper
} from './styles';
import IcStar from '../src/assets/icons/ic_star.png';
import IcElectric from '../src/assets/icons/ic_electric.png';
import IcDemo from '../src/assets/images/ic_demo.png';
import Bg1 from '../src/assets/images/bg_1.png';
import Bg2 from '../src/assets/images/bg_2.png';
import './App.css';
import {EventName, useFirebaseTracking} from "./hooks/firebase/useFirebaseTracking";

const Videos = ['https://static.apero.vn/Artimind-web/video1.mp4', 'https://static.apero.vn/Artimind-web/video2.mp4']

function App() {
  const { onEventTracking } = useFirebaseTracking();
  const videoRef: any = useRef(null);
  const videoRef1: any = useRef(null);

  const [url , setUrl] = useState(Videos[0]);

  const handleEnd = () => {
    if (url === Videos[0]) {
      setUrl(Videos[1])
    } else {
      setUrl(Videos[0])
    }
  }

  useEffect(() => {
    if (videoRef?.current && url === Videos[0]) {
      videoRef.current.play();
    }
    if (videoRef1?.current && url === Videos[1]) {
      videoRef1.current.play();
    }
  }, [url])

  const onClickCta = () => {
    onEventTracking(EventName.CLICK_CTA);
    window.open('https://apps.apple.com/us/app/ai-artimind-ai-art-generator/id6459831969?ppid=74370d3e-b76e-4d85-ba2e-b82b8dc4e3a5', '_blank');
  }

  return (
    <Wrapper>
      <FrameBody>
        <TxtTitle>artimind</TxtTitle>
        <Border1>
          <FrameTpe className={"border-item"}>
            <img src={IcStar} height={21} width={21}/>
            <TxtPro>PRO</TxtPro>
          </FrameTpe>
          <Border2>
            <Border3>
              <ImgBg1 src={Bg1}/>
              <ImgBg2 src={Bg2}/>
              <TxtDes1>
                <img src={IcElectric} height={20} width={20} style={{marginBottom: -5}}/>
                Super fast and high quality AI photo creation application
                <img src={IcElectric} height={20} width={20} style={{marginBottom: -5}}/>
              </TxtDes1>
              <Border4>
                <TxtDes1>
                  <img src={IcElectric} height={20} width={20} style={{marginBottom: -5}}/>
                  Unlimited creativity with countless styles
                  <img src={IcElectric} height={20} width={20} style={{marginBottom: -5}}/>
                </TxtDes1>
                <Border5>
                  <Border6>
                    <FrameVideo display={url === Videos[0]} ref={videoRef} style={{objectFit: 'cover'}} key={'video1'} playsInline={true}
                                controls={false} autoPlay={false} muted loop={false} onEnded={handleEnd}>
                      <source src={Videos[0]} type="video/mp4"/>
                    </FrameVideo>
                    <FrameVideo display={url === Videos[1]} ref={videoRef1} style={{objectFit: 'cover'}} key={'video2'} playsInline={true}
                                controls={false} autoPlay={false} muted loop={false} onEnded={handleEnd}>
                      <source src={Videos[1]} type="video/mp4"/>
                    </FrameVideo>
                  </Border6>
                </Border5>
                <TxtA key={url} className={"animated-text"} text1={url === Videos[0]}>{url === Videos[0] ? 'Professional AI Headshots' : 'Amazing Various Styles'}</TxtA>
              </Border4>
            </Border3>
          </Border2>
          <FrameNow onClick={onClickCta}>
            <img src={IcStar} height={24} width={24}/>
            <TxtNow>Discover now</TxtNow>
          </FrameNow>
        </Border1>
      </FrameBody>
    </Wrapper>
  );
}

export default App;
